@keyframes animation {
    0%  {background-color:#cdcdcd;} 100% {background-color: "transparent";}
  }
  @keyframes animation2 {
    0%  {background-color:#cdcdcd;} 100% {background-color: "transparent";}
  }
    .currentEvent {
      -webkit-animation-name: animation;
      -webkit-animation-duration: 10s;
      -webkit-animation-timing-function: ease-out;
      -webkit-animation-iteration-count: 1;
      -webkit-animation-play-state: running;
        animation-name: animation;
        animation-duration: 5s;
        animation-timing-function: ease-out;
        animation-iteration-count: 1;    
        animation-play-state: running;
    }.currentEvent2 {
      -webkit-animation-name: animation;
      -webkit-animation-duration: 10s;
      -webkit-animation-timing-function: ease-out;
      -webkit-animation-iteration-count: 1;
      -webkit-animation-play-state: running;
        animation-name: animation2;
        animation-duration: 5s;
        animation-timing-function: ease-out;
        animation-iteration-count: 1;    
        animation-play-state: running;
    }
  