.characteristicsInput {
    max-width: 4em;
}
.bg {
    background-image: url("../../images/builder-bg.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height:100%;
  }
  .characterBanner {
    background-color:#2F7589;
}
.selected {
  width: 200px;
  height: 100px;
  padding: 20px;
  background-color: #93cec6;
  border: 3px solid #2F588A;
  border-radius: 5px;
  display:flex;
  cursor: pointer;
}
.unselected {
  width: 200px;
  height: 100px;
  padding: 20px;
  border: 3px solid #cdcdcd;
  border-radius: 5px;
  display:flex;
  cursor: pointer;
}
.row {min-height: 20px}
.spellRow {min-height: 24px}
.row:nth-child(even) {
  background-color: #fff;
}
.row:nth-child(odd) {
  background-color: #eaeaea;
}
.row:hover {
  background-color: #CBDAED ;
}
.MuiDialogContent-root {
  padding: 0px !important
}
@keyframes animation {
  0%  {background-color:#93cec6;} 100% {background-color: "#cdcdcd";}
}
@keyframes animation2 {
  0%  {background-color:#93cec6;} 100% {background-color: "#cdcdcd";}
}
  .currentEvent {
    -webkit-animation-name: animation;
    -webkit-animation-duration: 10s;
    -webkit-animation-timing-function: ease-out;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-play-state: running;
      animation-name: animation;
      animation-duration: 5s;
      animation-timing-function: ease-out;
      animation-iteration-count: 1;    
      animation-play-state: running;
  }.currentEvent2 {
    -webkit-animation-name: animation;
    -webkit-animation-duration: 10s;
    -webkit-animation-timing-function: ease-out;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-play-state: running;
      animation-name: animation2;
      animation-duration: 5s;
      animation-timing-function: ease-out;
      animation-iteration-count: 1;    
      animation-play-state: running;
  }
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
.rotate {
  margin: 0 auto;
  width: 120px;
  height: 120px;
  -webkit-animation:spin 4s linear infinite;
  -moz-animation:spin 4s linear infinite;
  animation:spin 4s linear infinite;
}