@import url('https://fonts.googleapis.com/css2?family=Trebuchet MS:wght@300;500&display=swap');

body {
    font-family: 'Trebuchet MS', 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-size: 15px;
}
button {
    font-size: 15px;
    font-family: 'Trebuchet MS', 'Roboto', 'Helvetica', 'Arial', sans-serif;
}
a, a:visited, a:hover {
    color:#f4f4f4;
    text-decoration: none;
}
p {
    padding: 0
}
.contentLine {
    display: flex; 
    align-items: center;
}
/* .contentLine {
    display: flex; 
    flex-direction: column;
    align-items: "center";
} */
.navBanner {
    height:50px;
    background-color:#232323;
    display:flex;
    width: 100%;
    position: absolute;
    z-index: 999;
}

.menu li {
    float:left;
    text-decoration: none;
    list-style-type:none;
    margin-right:30px;
    color: #fff
}
.panel::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}
.panel {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
  .halfPanel {
    width: 49%;
    margin-left:auto;
    align-items:flex-start;
    flex-direction: column;
}
.thirdPanel {
    display:flex;
    width: 33%;
    margin-left:auto;
    align-items:flex-start;
    flex-direction: column;
}
.quarterPanel {
    display:flex;
    width: 25%;
    margin-left:auto;
    align-items:flex-start;
    flex-direction: column;
}
.horizontalLine {
    border-top: 2px solid #8a612f;
    margin: 10px 0px;
}
.builderFooter {
    background-color:#eceff1;
    position: fixed;
    bottom: 0;
    height:6%;
    width:100%;
}
.next {
    height:100%;
    padding: 0px 20px 0px 20px;
    margin-top: 10px;
    margin:10px 20px 0px 20px;
    min-width: 10em;
}
.personalInput {
    margin-top: 20px;
}
.mainBanner {
    height:82px;
    display:flex;
    align-items: center;
}
.MuiSvgIcon-root path {
    color:white;
}
.runequestVermillion {
    color: #851113;
  }
.runequestCopper {
    color: #b3722a;
}
.runequestBronze {
    color: #8a612f;
}
.runequestTurquoise {
    color: #2F7589;
}
.runequestCrimson {
    color: #a71d21;
}
.runequestBlue {
    color: #2F588A;
}
.runequestGray {
    color: #666666;
}
.runeQuestWhite {
    color: #fff;
}
.runeQuestPink {
    color: #a05889;
}
.runeQuestPaleBlue {
    color: #bcd0e8;
}

.fumble {
    background-color: "#a71d21";
    color:"white";
}
.buttonTile {
    border-radius: .5rem;
    border: none;
    font-size: 14px;
    font-weight: 400;
    color: #2F588A;
    text-align: center;
    backdrop-filter: blur(10px);
    cursor: pointer;
    width: 100%;
    font-family: 'Trebuchet MS', 'Roboto', 'Helvetica', 'Arial', sans-serif;
}
.buttonTile:hover {
    opacity: 0.8;
}
.buttonTile::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border-radius: .5rem;
    background: linear-gradient(180deg, rgba(8, 77, 126, 0) 0%, rgba(8, 77, 126, 0.42) 100%),rgba(137,47,108,.24);
    box-shadow: inset 0 0 12px rgba(151,200,255,.44);
    z-index: -1;
  }
  
  .buttonTile::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(8, 77, 126, 0) 0%, rgba(8, 77, 126, 0.42) 100%),rgba(137,47,108,.24);
    box-shadow: inset 0 0 12px rgba(151,200,255,.44);
    border-radius: .5rem;
    opacity: 0;
    z-index: -1;
    transition: all .3s ease-in;
  }
.floatingButton {
    width:50px;
    height:50px;
    border-radius:30px;
    border: 2px solid #2f588a;
    background-color:#fff;
    display:flex;
    justify-content:center;
    align-items:center;
    margin-top:10px;
    cursor: pointer
}
.blueDiceButton {
    background-color:#2F588A;
    color: #fff;
    border: 2px solid #2F588A;
    border-radius: 5px;
    cursor: pointer;
}
.weaponButton {
    background-color:#2F588A;
    color: #fff;
}
.largeDamageButton {
    background-color:#851113;
    color: #fff;
    width: 100%;
    border-radius: 5px;
}
.augmentButton {
    background-color:#2F7589;
    border: 2px solid #2F588A;
    cursor: pointer;
    color: #fff;
    border-radius: 5px;
}

  .disabledButton {
    background-color:#959595 !important;
    color: #fff;
    cursor: not-allowed !important;
    opacity: 0.6 !important;
}
.activeButton {
    background-color:#2f588a !important;
    border: 2px solid #2F7589;
    color: #fff;
    cursor: pointer;
    opacity: 1;
    width: 100%;

}
.runeButton {
    background-color: #2F588A;
}
.blueShadow {

    /* text-shadow: 2px 7px 5px rgba(0,0,0,0.3), 
        0px -4px 10px rgba(255,255,255,0.3); */
        width: 126px;
        display: flex;
        justify-content: center;
        align-items: center;
    
    }
.tab {
    background-color: #2F7589;
    opacity: 1
}
.tab:hover {
    background-color: #2F7589;
    opacity: 0.8;
}
.tile {
    background: linear-gradient(rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0) 50.01%), #2F588A;
    margin: 2px;
    border-radius: 5px;
    font-size: 14px;
    font-family: Trebuchet MS, Roboto, Helvetica, Arial, sans-serif;
}
.tile:hover {
    opacity: 0.8;
    /* background: linear-gradient(rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0) 50.01%), #2F7589; */
}
.contextMenuItem {
    border-bottom: 1px solid #757575;
    padding:3px;
}
.contextMenuItem:hover {
    background-color: #e4e6e4;
}
div[role=dialog] {
    border-radius: 10px !important;
}
.MuiDialogContent-root:first-child {
    padding: 0px !important;
}
.accordionTitle {
    margin-left:20px; 
    font-size: 20px;
    display: flex
}

.contextBar {
    position: fixed;
  float: right;
  right: 0px;
  top: 0px;
  z-index: 990;
}

.resultBar {
    position: fixed;
  float: right;
  bottom: 50px;
  z-index: 999;
}
.hiddenResult { 
    display: none;
}
#contextPanel {
    width: 100vw;
    height: 100vh;
    background-color: #FFE600;
    position: fixed;
    top: 0;
    left: 0;
    transition: transform .3s
                cubic-bezier(0, .52, 0, 1);
    overflow: scroll;
    z-index: 1000;
  }
   
  .hidePanel {
    width: 100vw;
    height: 100vh;
    position: absolute;
    transition: transform 2s
                cubic-bezier(0, .52, 0, 1);
    overflow: scroll;
    z-index: 1000;
    transform: translate3d(1999px, 0, 0);
    overflow: hidden;
  }
   
  .showPanel {
    width: 100vw;
    height: 100vh;
    position: absolute;
    transition: transform .3s
                cubic-bezier(0, .52, 0, 1);
    overflow: scroll;
    z-index: 1000;
    overflow: hidden;
  }
  .expandableTile:hover {
    background-color: #bcd0e8;
    cursor: zoom-in;
  }
  .expandableTile:hover .diceButton {
    background-color: #2F588A;
    color: #fff;
    border-radius: 3px;
}
.expandableTile:hover .weaponButton {
    background-color: #2F588A;
    color: #fff;
    border-radius: 3px;
}
.expandableTile:hover .damageButton {
    background-color: #851113;
    color: #fff;
    border-radius: 3px;
}
.expandableTile:hover .disabledButton {
    background-color: #959595;
    color: #fff;
    border-radius: 3px;
}
.row {
    min-height: 20px;
}
.spellRow {min-height: 24px}
.row:nth-child(even) {
  background-color: #fff;
}
.row:nth-child(odd) {
  background-color: #eaeaea;
}
.row:hover {
  background-color: #CBDAED ;
}
.spellRow:nth-child(even) {
    background-color: #cdcdcd;
  }
  .spellRow:nth-child(odd) {
    background-color: #E8EEED;
  }
  .spellRow:hover {
    background-color: #ababab ;
  }
@-webkit-keyframes fadeOut {
    0% {opacity: 1; visibility: visible;}
    100% {opacity: 0; visibility: hidden;}
 }
 
 @keyframes fadeOut {
  0% {opacity: 1; visibility: visible;}
  100% {opacity: 0; visibility: hidden;}
}
 
@keyframes fadeOut2 {
    0% {opacity: 1; visibility: visible;}
    100% {opacity: 0; visibility: hidden;}
  }
 
  @keyframes instantFade {
    0% {opacity: 1; visibility: visible;}
    100% {opacity: 0; visibility: hidden;}
  }
     
.fadeOut {
    animation: fadeOut 2s 6s linear both;
    z-index: 9999;
    position: relative;
  }
   
  .fadeOut2 {
    animation: fadeOut2 2s 6s linear both;
    z-index: 9999;
    position: relative;
  }
   
.fastFade {
    animation: fadeOut 2s 1s linear both;
  }
   
  .fastFade2 {
    animation: fadeOut2 2s 1s linear both;
  }
  .instantFade {
    animation: fadeOut2 1s 0s linear both;
  }
  
  .show {
  opacity: 0; 
  visibility: hidden;
  z-index: 999;
}
.showCanvas {
    opacity: 1;
    visibility: visible;
    z-index: 999;
}
.hideCanvas {
    opacity: 0;
    visibility: hidden;
    z-index: 999;
}
@-webkit-keyframes moveToTopFade {
	from { }
	to { opacity: 0.3; -webkit-transform: translateY(-220%); }
}
@keyframes moveToTopFade {
	from { }
	to { opacity: 0.3; -webkit-transform: translateY(-220%); transform: translateY(-220%); }
}

@-webkit-keyframes moveFromTopFade {
	from { opacity: 0.3; -webkit-transform: translateY(-100%); }
}
@keyframes moveFromTopFade {
	from { opacity: 0.3; -webkit-transform: translateY(-100%); transform: translateY(-100%); }
}

@-webkit-keyframes moveToBottomFade {
	from { }
	to { opacity: 0.3; -webkit-transform: translateY(100%); }
}
@keyframes moveToBottomFade {
	from { }
	to { opacity: 0.3; -webkit-transform: translateY(100%); transform: translateY(100%); }
}

@-webkit-keyframes moveFromBottomFade {
	from { opacity: 0.3; -webkit-transform: translateY(100%); }
}
@keyframes moveFromBottomFade {
	from { opacity: 0.3; -webkit-transform: translateY(100%); transform: translateY(100%); }
}

@-webkit-keyframes moveFromBottomFade2 {
	from { opacity: 0.3; -webkit-transform: translateY(100%); }
}
@keyframes moveFromBottomFade2 {
	from { opacity: 0.3; -webkit-transform: translateY(100%); transform: translateY(100%); }
}

.moveToTopFade {
	-webkit-animation: moveToTopFade 1.7s ease both;
	animation: moveToTopFade 1.7s ease both;
}

.moveFromTopFade {
	-webkit-animation: moveFromTopFade .3s ease both;
	animation: moveFromTopFade .3s ease both;
}

.moveToBottomFade {
	-webkit-animation: moveToBottomFade 1s ease both;
	animation: moveToBottomFade 1s ease both;
}

.moveFromBottomFade {
	-webkit-animation: moveFromBottomFade 1s ease both;
	animation: moveFromBottomFade 1s ease both;
}

.moveFromBottomFade2 {
	-webkit-animation: moveFromBottomFade2 1s ease both;
	animation: moveFromBottomFade2 1s ease both;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
.rotate {
  margin: 0 auto;
  width: 120px;
  height: 120px;
  -webkit-animation:spin 4s linear infinite;
  -moz-animation:spin 4s linear infinite;
  animation:spin 4s linear infinite;
}
@media print {
    .pagebreak { page-break-before: always; } /* page-break-after works, as well */
    .hidePrint {
        display:none;
    }
}
@media print {
    aside {
        break-inside: avoid;
    }
}
  