
.characterBanner {
    background-color:#8a612f;
}
.bg {
  background-image: url("../../images/character-bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height:100%;
}
.editingBanner {
    background-color:#851113;
}
.skillButton {
    height: 20px;
    max-width: 30px;
    box-sizing: border-box;
    cursor: pointer;
}
.itemInput {
    height: 20px;
    box-sizing: border-box;
    font-size: 13px;
}
.diceButton {
    background-color:"#2F588A";
    opacity: "0.5";
    color: "white";
    height: "20px";
    width:"40px";
    display:"flex";
    padding:"0px 5px";
    align-items:"center";
    cursor:"pointer";
    border-radius: "3px"
}
.container {
    position: relative;
    text-align: center;
    cursor: pointer;
  }
  
.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .skillsPanel {
    margin-left:"20px";
    font-size:"13px";
    flex-direction:"column";
    width:"50%";
    justify-content:"space-between";
  }
  .foo{
    display: block;
    position: relative;
  
}


  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
    .skillsPanel {
        width:100%;
    }
    
  }