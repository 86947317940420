
.characterBanner {
    background-color:#8a612f;
}
.bg {
  background-image: url("../../images/encounters-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height:100%;
}
.editingBanner {
    background-color:#851113;
}
.skillButton {
    height: 20px;
    max-width: 30px;
    box-sizing: border-box;
    cursor: pointer;
}
.itemInput {
    height: 20px;
    box-sizing: border-box;
    font-size: 13px;
}
.itemRow {
  background-color:transparent;
  cursor:pointer;
  border: 1px solid transparent;
  border-radius: 3px;
  padding: 0px 4px;
}
.itemRow:hover {
  /* background-color:#bcd0e8; */
  cursor:pointer;
  border: 1px solid #2F588A;
  border-radius: 3px;
  padding: 0px 4px;
}
.diceButton {
  border: 1px solid transparent;
}
.diceButton:hover {
  cursor: pointer !important;
  border: 1px solid #2F588A;
  /* border-radius: 3px; */
  background-color: #bcd0e8 !important;
}
.disabledButton {
  border: 1px solid transparent;
  opacity: 0.6;
  cursor: not-allowed;
}
.disabledButton:hover {
  border: 1px solid #2F588A;
  border-radius: 3px;
  cursor: default;
}
.container {
    position: relative;
    text-align: center;
    cursor: pointer;
  }
  
.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .skillsPanel {
    margin-left:"20px";
    font-size:"13px";
    flex-direction:"column";
    width:"50%";
    justify-content:"space-between";
  }
  .foo{
    display: block;
    position: relative;
  
}

.spellList {
  display:block;
  padding: 0px 5px;
}


  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
    .skillsPanel {
        width:100%;
    }
    
  }