
.diceButton {
  border: 1px solid transparent;
}
.diceButton:hover {
  cursor: pointer !important;
  border: 1px solid #2F588A;
  /* border-radius: 3px; */
  background-color: #bcd0e8 !important;
}
.disabledButton {
  border: 1px solid transparent;
  opacity: 0.6;
  cursor: not-allowed;
}
.disabledButton:hover {
  border: 1px solid #2F588A;
  border-radius: 3px;
  cursor: default;
}
.tableRow:nth-child(odd) {
    background-color: #eaeaea;
    border: 1px solid transparent;
}
.tableCell {
  font-size: 13px;
  padding: "3px";
  font-family:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif";
}
.header {
  background-color: #2F588A;
  color: #fff;
  font-family:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif";
  font-size: "14px";
  width: 60px;
  padding: 3px;
}
  
