.accordionClosed {
  /* css animation */
  overflow: hidden;
  max-height: 0;
  opacity: 0;

  /* add browser prefixes */
  transition: all 0.3s ease;
}

.accordionOpen {
  opacity: 1;
}
.sortOption {
  width:28px;
  height: 28px;
  padding:4px;
  border: 1px solid #ffffff;
  margin-left: 10px;
  border-radius: 5px;
}
.selectedItem {
  background-color:#8a612f;
  color: #fff;
  cursor: pointer;
  border: 1px solid #454645;
}
.selectItem {
  border: 1px solid #454645;

}
.selectItem:nth-child(even) {
  background-color: #a1bcde;
}
.selectItem:nth-child(odd) {
  background-color: #E8EEED;
}
.selectItem:hover {
  background-color:#D8BFD8;
  cursor: pointer;
}

.spellList {
  padding: 5px
}
.button {
  width:50px;
  height:50px;
  border-radius:25px;
  background-color:#2F588A;
  margin-top: 10px;
}









.characterBanner {
    background-color:#8a612f;
}
.bg {
  background-image: url("../../images/character-bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height:100%;
}
.editingBanner {
    background-color:#851113;
}
.skillButton {
    height: 20px;
    max-width: 30px;
    box-sizing: border-box;
    cursor: pointer;
}
.itemInput {
    height: 20px;
    box-sizing: border-box;
    font-size: 13px;
}
.diceButton {
    background-color:"#2F588A";
    opacity: "0.5";
    color: "white";
    height: "20px";
    width:"40px";
    display:"flex";
    padding:"0px 5px";
    align-items:"center";
    cursor:"pointer";
    border-radius: "3px"
}
.container {
    position: relative;
    text-align: center;
    cursor: pointer;
  }
  
.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .skillsPanel {
    margin-left:"20px";
    font-size:"13px";
    flex-direction:"column";
    width:"50%";
    justify-content:"space-between";
  }
  .foo{
    display: block;
    position: relative;
  
}

.roundButton {
  width: 50px;
  height: 50px;
  border-radius:50%;
  background-color: #fff;
  display:flex;
  justify-content:center;
  align-items:center;
  cursor: pointer
}
.glowingBlueButton {
  -webkit-animation: glowingBlue 1s ease-in-out infinite alternate;
  -moz-animation: glowingBlue 1s ease-in-out infinite alternate;
  animation: glowingBlue 1s ease-in-out infinite alternate;
}
@-webkit-keyframes glowingBlue {
  from {
      box-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #27707d, 0 0 20px #a1bcde, 0 0 25px #4179be, 0 0 30px #4179be, 0 0 35px #4179be;
  }
  to {
    box-shadow: 0 0 10px #fff, 0 0 15px #2F588A, 0 0 20px #2F588A, 0 0 25px #2F588A, 0 0 30px #2F588A, 0 0 35px #2F588A, 0 0 40px #2F588A;
  }
}  @keyframes glowingBlue {
  from {
    box-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #27707d, 0 0 20px #a1bcde, 0 0 25px #4179be, 0 0 30px #4179be, 0 0 35px #4179be;
  }
  to {
    box-shadow: 0 0 10px #fff, 0 0 15px #2F588A, 0 0 20px #2F588A, 0 0 25px #2F588A, 0 0 30px #2F588A, 0 0 35px #2F588A, 0 0 40px #2F588A;
  }
}
.glowingRedButton {
  -webkit-animation: glowingRed 1s ease-in-out infinite alternate;
  -moz-animation: glowingRed 1s ease-in-out infinite alternate;
  animation: glowingRed 1s ease-in-out infinite alternate;
}
@-webkit-keyframes glowingRed {
  from {
      box-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #27707d, 0 0 20px #a1bcde, 0 0 25px #4179be, 0 0 30px #4179be, 0 0 35px #4179be;
  }
  to {
    box-shadow: 0 0 10px #fff, 0 0 15px #2F588A, 0 0 20px #851113, 0 0 25px #851113, 0 0 30px #851113, 0 0 35px #851113, 0 0 40px #851113;
  }
}  @keyframes glowingRed {
  from {
    box-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #27707d, 0 0 20px #a1bcde, 0 0 25px #4179be, 0 0 30px #4179be, 0 0 35px #4179be;
  }
  to {
    box-shadow: 0 0 10px #fff, 0 0 15px #851113, 0 0 20px #851113, 0 0 25px #851113, 0 0 30px #851113, 0 0 35px #851113, 0 0 40px #851113;
  }
}



  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
    .skillsPanel {
        width:100%;
    }
    
  }

  @keyframes animation {
    0%  {background-color:#fff; color: #121312;} 100% {background-color: #121312; color: #fff;}
  }
  @keyframes animation2 {
    0%  {background-color:#fff; color: #121312;} 100% {background-color: #121312; color: #fff;}
  }
    .itemDisplay {
      -webkit-animation-name: animation;
      -webkit-animation-delay: 1s;
      -webkit-animation-duration: 5s;
      -webkit-animation-timing-function: ease-out;
      -webkit-animation-iteration-count: 1;
      -webkit-animation-play-state: running;
        animation-name: animation;
        animation-delay: 0.5s;
        animation-duration: 5s;
        animation-timing-function: ease-out;
        animation-iteration-count: 1;    
        animation-play-state: running;
    }.itemDisplay2 {
      -webkit-animation-name: animation;
      -webkit-animation-delay: 1s;
      -webkit-animation-duration: 5s;
      -webkit-animation-timing-function: ease-out;
      -webkit-animation-iteration-count: 1;
      -webkit-animation-play-state: running;
        animation-name: animation2;
        animation-delay: 0.5s;
        animation-duration: 5s;
        animation-timing-function: ease-out;
        animation-iteration-count: 1;    
        animation-play-state: running;
    }
  










    .diceContainer {
      position: fixed;
      top: 0;
      left: 0;
      display: flex;
      overflow: hidden;
      z-index: 9999;
    }
    
    #svg *,
    .svg * {
      -moz-user-select: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -o-user-select: none;
      user-select: none;
    }
    
    #waitform {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 10000;
      cursor: wait;
    }
    
    h6 {
      font-size: 100%;
      font-weight: normal;
      margin: 0px;
    }
    
    .control_panel {
      padding: 15px;
      padding-bottom: 0px;
      position: absolute;
      z-index: 10;
    }
    
    .center_field {
      position: absolute;
      text-align: center;
      /* height: calc(100% - 76px); */
      height: 100vh;
      width: 100%;
    }
    
    .center_field * {
      position: relative;
      font-family: Trebuchet MS;
      /* background-color: rgba(255, 255, 255, 0.6); */
      padding: 5px 15px;
    }
    
    .center_field br {
      background-color: rgba(0, 0, 0, 0);
    }
    
    .bottom_field {
      position: absolute;
      text-align: center;
      bottom: 5px;
      width: inherit;
      padding: 0px;
    }
    
    #label {
      font-size: 32pt;
      word-spacing: 0.5em;
      padding: 5px 15px;
      color: rgba(21, 26, 26, 0.6);
      top: 45%;
    }
    
    #labelhelp {
      font-size: 12pt;
      padding: 5px 15px;
      color: rgba(21, 26, 26, 0.5);
      bottom: 50px;
    }
    
    #set {
      text-align: center;
      font-size: 26pt;
      border: none;
      color: rgba(0, 0, 0, 0.8);
      background-color: rgba(255, 255, 255, 0);
      top: 60%;
    }
    
    #sethelp {
      font-size: 12pt;
      color: rgba(21, 26, 26, 0.5);
      background: none;
      top: 25%;
    }
    
    #selector_div button {
      font-size: 20pt;
      color: rgb(255, 255, 255);
      background-color: rgba(0, 0, 0, 0.6);
      cursor: pointer;
      border: none;
      width: 5em;
      top: 62%;
    }
    
    .dice_place {
      position: absolute;
      border: solid black 1px;
    }