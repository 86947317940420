
.button {
    width:50px;
    height:50px;
    border-radius:30px;
    background-color:#851113;
    margin-top: 10px;
  }
  
.roundButton {
    margin-top: 10px;
    width: 50px;
    height: 50px;
    border-radius:50%;
    background-color: #fff;
    display:flex;
    justify-content:center;
    align-items:center;
    cursor: pointer
  }

  .glowingRedButton {
    -webkit-animation: glowingRed 1s ease-in-out infinite alternate;
    -moz-animation: glowingRed 1s ease-in-out infinite alternate;
    animation: glowingRed 1s ease-in-out infinite alternate;
  }
  @-webkit-keyframes glowingRed {
    from {
        box-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #27707d, 0 0 20px #a1bcde, 0 0 25px #4179be, 0 0 30px #4179be, 0 0 35px #4179be;
    }
    to {
      box-shadow: 0 0 10px #fff, 0 0 15px #2F588A, 0 0 20px #851113, 0 0 25px #851113, 0 0 30px #851113, 0 0 35px #851113, 0 0 40px #851113;
    }
  }  @keyframes glowingRed {
    from {
      box-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #27707d, 0 0 20px #a1bcde, 0 0 25px #4179be, 0 0 30px #4179be, 0 0 35px #4179be;
    }
    to {
      box-shadow: 0 0 10px #fff, 0 0 15px #851113, 0 0 20px #851113, 0 0 25px #851113, 0 0 30px #851113, 0 0 35px #851113, 0 0 40px #851113;
    }
  }
  