body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
p {
  margin-top: 10px
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  display: none;
}

.mb-0 {
  margin-bottom: 0;
}

nav {
  position: relative;
  z-index: 2;
  background-color: #fff;
  padding: 16px 0;
}

nav ul {
  margin: 0;
}

#label {
  background: rgba(0, 0, 0, 0.6);
  font-size: 32pt;
  word-spacing: 0.5em;
  padding: 5px 15px;
  color: #fff;
  top: 45%;
}

#labelhelp {
  font-size: 12pt;
  padding: 5px 15px;
  color: rgba(255, 255, 255, 1);
  bottom: 50px;
}

#waitform {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10000;
  cursor: wait;
}

#set {
  text-align: center;
  font-size: 26pt;
  border: none;
  color: #fff;
  text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4), 0px 8px 13px rgba(0, 0, 0, 0.1), 0px 18px 23px rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0);
  top: 60%;
}

#sethelp {
  font-size: 12pt;
  color: rgba(255, 255, 255, 1);
  text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4), 0px 8px 13px rgba(0, 0, 0, 0.1), 0px 18px 23px rgba(0, 0, 0, 0.1);
  background: none;
  top: 25%;
}

#selector_div button {
  font-size: 20pt;
  color: rgb(255, 255, 255);
  background-color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  border: none;
  width: 5em;
  top: 62%;
}